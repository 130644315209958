const groupifyMoves = [
  // "Hierarchy",
  "Democracy",
  "Market",
  "Community",
  "Ecosystem"
];

const cognifyMoves = [
  "Create",
  "Sense",
  "Learn",
  "Decide",
  "Remember"
];

export { groupifyMoves, cognifyMoves };
